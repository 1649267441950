import { Component, OnInit, Renderer2, NgModule} from '@angular/core';
import { bmxurl} from '../../globals';
import { ActivatedRoute, Router } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AvailableTimes, AvailabilityReq, Package_returndata, Customer, Payment } from '../../PackageClasses';
import { customer } from '../../types/customer';
import { booking } from '../../types/booking';
import { MessageService } from 'primeng/api';
import { cExtra } from '../../types/extra';
import { cPackageExtra } from '../../types/packageExtra';
import { cPirequest, cPirequestVoucher } from '../../types/pirequest';
import { piresponse_returndata, piresponsevoucher_returndata } from '../../types/piresponse_returndata';
import { venuesettings_returndata } from '../../types/venuesettings_returndata';
import { cResource, resource } from '../../types/resource';
import { BusyService } from '../_services/busy.service';
import { customer_returndata } from '../../types/customer_returndata';
import { terms_returndata } from '../../types/terms_returndata';
import { cTerms } from '../../types/terms';
import { admission, cAdmission } from '../../types/admission';
import { packagegroups_returndata } from '../../types/packagegroup_returndata';
import { booking_returndata } from '../../types/booking_returndata';
import { host_returndata } from '../../types/host_returndata';
import { bookingsummary, cBookingsummary } from '../../types/bookingsummary';
import { bookingJWT } from '../../types/bookingJWT';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { environment } from '../../environments/environment';
import { cDiscountvalidate } from '../../types/discountvailidate';
import { map, takeWhile } from 'rxjs/operators';
import { timer } from 'rxjs';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';
import { bookingquestionitem } from '../../types/bookingquestion';
import { birthdayanswers, questionanswers } from '../../types/questionanswers';
import { cVouchervalidate } from '../../types/vouchervalidate';
import { cPackage, packageObj } from '../../types/package';
import { venueclosings_returndata } from '../../types/venueclosings_returndata';
import { ViewportScroller } from '@angular/common';
import { package_returndata } from '../../types/package_returndata';
import { link_returndata } from '../../types/link_returndata';
import { LoadingInterceptor } from '../_interceptors/loading.interceptor';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule} from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { v4 as uuidv4  } from 'uuid';
import { InputTextModule } from 'primeng/inputtext';
import { DateAdapter } from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {provideNativeDateAdapter} from '@angular/material/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { voucherreq } from '../../types/voucherreq';
import { voucher_returndata } from '../../types/voucher_returndata';

declare var Stripe: any;

@Component({
  selector: 'app-buyvoucher',
  standalone: true,
  imports: [
    HttpClientModule,
    DialogModule,
    ButtonModule,
    AccordionModule,
    CalendarModule,
    ToggleButtonModule,
    InputNumberModule,
    InputSwitchModule,
    SelectButtonModule,
    RadioButtonModule,
    ProgressSpinnerModule,
    FormsModule,
    DatePipe,
    CommonModule,
    DropdownModule,
    SelectButtonModule,
    MultiSelectModule,
    InputTextModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    NgxSpinnerModule,
  ],
  providers: [ 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    provideNativeDateAdapter(),
    HttpClientModule,
    MessageService
 ],
  templateUrl: './buyvoucher.component.html',
  styleUrls: ['./buyvoucher.component.css']
})
export class BuyvoucherComponent implements OnInit {

  publicImageURL: string = "";
  stateOption: any;
  CustomAmount: any;

  customAmountValue: number = 0;
  toggle5: boolean = false;
  toggle10: boolean = false;
  toggle20: boolean = false;
  toggle25: boolean = false;
  toggle50: boolean = false;
  toggle100: boolean = false;

  stateOptions: any[];
  NameValid: boolean = true;
  EmailValid: boolean = true;
  EmailFormatValid: boolean = true;

  RecipientNameValid: boolean = true;
  RecipientEmailValid: boolean = true;
  RecipientEmailFormatValid: boolean = true;

  ValueValid: boolean = true;

  messageValid: boolean = true;
  Voucher: any;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  pageReady: boolean = true;
  id: string = "";
  totalValueOfBooking: number = 0;
  
  VoucherPayments: Payment[] = [];
  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;

  paymentValueValid: boolean = true;
  paymentValueExceededValid: boolean = true;
  paymentDepositValid: boolean = true;
  PaymentTypes: any[] = [];

  version: string = environment.version;

  ShowPayment: boolean = false;
  paymentError: string = "";
  RyftSubAccountID: string = "";

  imageWidth: number = 150;
  imageHeight: number = 150;
  defaultColour: string = "#ffff00";
  EmailSMSConfirmation: string = "";
  GoogleTagManagerID : string = "";
  returnPage : string = "";
  MinimalCustomerDetails: boolean = false;

  PIReponse: any;
  PIRequest: cPirequestVoucher | undefined;

  PauseTimerEffect: boolean = false;
  PaymentInProgress: boolean = false;

  venueName: string = "";
  VenueCSS: any;

  BookingTimeElasped: boolean = false;
  BookingComplete: boolean = false;
  VoucherResponse: any;
  VoucherConfirmationSummary: string = "";
  VoucherConfirmationText: string = "";
  hubConnection!: HubConnection;


  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private sanitizer: DomSanitizer, private busyService: BusyService) { 
    this.stateOptions = [{ label: 'No', value: false }, { label: 'Yes', value: true }];
    this.Voucher = {
      id: null,
      venueid: this.VenueID,
      vouchercode: "",
      emailaddress: "",
      customername: "",
      alternativerecipient: false,
      recipientsname: "",
      recipientsemailsaddress: "",
      message: "",
      disabled: false,
      created: new Date(),
      expiry: new Date(),
      initialvalue: 0,
      customamount: false,
      balance: 0,
      payments: []
    }

    this.route.params.subscribe(params => {
      let param = params['venue'];

      this.http.get(bmxurl + "/host/" + param).subscribe(response => {
        
        this.httpResponse =  (response as host_returndata);

        if((response as host_returndata).succeeded)
        {
          if(this.httpResponse.data != null)
          {
            this.venueName = this.httpResponse.data.venuename;
            this.VenueID = this.httpResponse.data.venueid;
            localStorage.setItem("SmartVenueID", this.VenueID);

            let tempCSS = bmxurl + "/css/" + this.VenueID;
            this.VenueCSS = this.sanitizer.bypassSecurityTrustResourceUrl(tempCSS);

          }
          else
          {
            this.VenueID = "";
          }

          this.http.get(bmxurl + "venuesettings/" + this.VenueID).subscribe(response => {
          this.httpResponse =  (response as venuesettings_returndata)
          
          if(this.httpResponse.succeeded)
          {
            //Set Venue Settings ID
            
            localStorage.setItem("VenueSettingsID", this.httpResponse.data.id);
        
            this.httpResponse.data.venueSettings.forEach((element: any) => {

              if(element.name != null)
                {
                  localStorage.setItem(element.name, element.value);

                  if(element.name == "MinimalDetails")
                    this.MinimalCustomerDetails = element.value == "true" ? true : false;
                  if(element.name == "PublicImgURL")
                    this.publicImageURL = element.value;
                  if(element.name == "PublicImageheight")
                    this.imageHeight = element.value;
                  if(element.name == "PublicImageWidth")
                    this.imageWidth = element.value;
                  if(element.name == "DefaultColour")
                    this.defaultColour = element.value;
                  if(element.name == "EMailSMSConfirmation")
                    this.EmailSMSConfirmation = element.value;
                  if(element.name == "GoogleTagManagerID")
                    this.GoogleTagManagerID = element.value;
                  if(element.name == "RyftSubAccountID")
                    this.RyftSubAccountID = element.value;
                  if(element.name == "returnPage")
                    this.returnPage = element.value;
                }
            });
          }
          else{
          }
        }, error => {
        });

        }

      }, error => {
        console.log(error);
      })
    });
  }

  ngOnInit(): void {
    this.publicImageURL = localStorage.getItem("PublicImgURL") || "";

    this.http.get(bmxurl + "venuesettings/" + this.VenueID).subscribe(response => {
      this.httpResponse =  (response as venuesettings_returndata)
      
      if(this.httpResponse.succeeded)
      {
        //Set Venue Settings ID
        localStorage.setItem("VenueSettingsID", this.httpResponse.data.id);
    
        this.httpResponse.data.venueSettings.forEach((element: any) => {
          localStorage.setItem(element.name, element.value);

          if(element.name == "MinimalDetails")
            this.MinimalCustomerDetails = element.value == "true" ? true : false;
          if(element.name == "PublicImgURL")
            this.publicImageURL = element.value;
          if(element.name == "PublicImageheight")
            this.imageHeight = element.value;
          if(element.name == "PublicImageWidth")
            this.imageWidth = element.value;
          if(element.name == "DefaultColour")
            this.defaultColour = element.value;
          if(element.name == "EMailSMSConfirmation")
            this.EmailSMSConfirmation = element.value;
          if(element.name == "GoogleTagManagerID")
            this.GoogleTagManagerID = element.value;
          if(element.name == "RyftSubAccountID")
            this.RyftSubAccountID = element.value;
          if(element.name == "returnPage")
            this.returnPage = element.value;
        });

      }
      else{
      }
    }, error => {
    });
  }

  customAmountToggle(){
    this.toggle5 = false;
    this.toggle10 = false;
    this.toggle20 = false;
    this.toggle25 = false;
    this.toggle50 = false;
    this.toggle100 = false;
  }

  setValue(){
    this.Voucher.initialvalue = this.customAmountValue;
  }

  selectValue(value: number){
    switch(value){  
      case 5: {
        this.toggle5 = true;
        this.toggle10 = false;
        this.toggle20 = false;
        this.toggle25 = false;
        this.toggle50 = false;
        this.toggle100 = false;
        this.Voucher.customamount = false;
        this.Voucher.initialvalue = 5;
        break;
      }
      case 10: {
        this.toggle5 = false;
        this.toggle10 = true;
        this.toggle20 = false;
        this.toggle25 = false;
        this.toggle50 = false;
        this.toggle100 = false;
        this.Voucher.customamount = false;
        this.Voucher.initialvalue = 10;
        break;
      }
      case 20: {
        this.toggle5 = false;
        this.toggle10 = false;
        this.toggle20 = true;
        this.toggle25 = false;
        this.toggle50 = false;
        this.toggle100 = false;
        this.Voucher.customamount = false;
        this.Voucher.initialvalue = 20;
        break;
      }
      case 25: {
        this.toggle5 = false;
        this.toggle10 = false;
        this.toggle20 = false;
        this.toggle25 = true;
        this.toggle50 = false;
        this.toggle100 = false;
        this.Voucher.customamount = false;
        this.Voucher.initialvalue = 25;
        break;
      }
      case 50: {
        this.toggle5 = false;
        this.toggle10 = false;
        this.toggle20 = false;
        this.toggle25 = false;
        this.toggle50 = true;
        this.toggle100 = false;
        this.Voucher.customamount = false;
        this.Voucher.initialvalue = 50;
        break;
      }
      case 100: {
        this.toggle5 = false;
        this.toggle10 = false;
        this.toggle20 = false;
        this.toggle25 = false;
        this.toggle50 = false;
        this.toggle100 = true;
        this.Voucher.customamount = false;
        this.Voucher.initialvalue = 100;
        break;
      }
    }
  }

  makePayment(){
    this.http.post(bmxurl + "CreatePublicVoucher", this.Voucher).subscribe(response => {
      let returnedVoucher: any = response;

      //Do SignalR
      this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.servicestarget + "/PublicBooking/" + returnedVoucher.voucherid + "/" + returnedVoucher.vouchertoken
      , { }).build();

      this.hubConnection.start();

      this.hubConnection.on("publicBookingEvent", data => {
          this.BookingComplete = true;
          this.PaymentInProgress = false;
          this.hubConnection.stop();
          this.busyService.idle();
      });

      this.SetupPayment(returnedVoucher.data);
      this.ShowPayment = true;
    });
  }

  DoSummary(VoucherResponse: any)
  {
    this.Voucher = VoucherResponse;
  }

  startAgain()
  {
    
  }

  private SetupPayment(returnedVoucher: any) {
    let newCustomer: customer = {
      id: null,
      customerid: null,
      firstName: "",
      lastName: "",
      fullName: this.Voucher.customername,
      address1: "",
      address2: "",
      townCity: "",
      postCode: "",
      email: this.Voucher.emailaddress,
      mobile: "",
      dob: new Date('0001-01-01T00:00:00Z'),
      venueId: this.VenueID,
      disabled: false,
      bookingid: "",
      agreedtotandc: true,
      agreedtoemailmarketing: false,
      agreedtosmsmarketing: false,
      jwt: ""
    }

    this.paymentError = "";

    let PKKey: string = environment.PaymentAccountNumber;
    let StripeAccount = this.RyftSubAccountID;

    const stripe = Stripe(PKKey,
      {
        stripeAccount: StripeAccount
      });

    //Go get the PI
    this.PIRequest = {
      amount: (this.Voucher.initialvalue * 100),
      currency: "GBP",
      description: "Voucher + " + returnedVoucher.vouchercode,
      voucherid: returnedVoucher.voucherid,
      venueid: this.VenueID,
      StripeAccount: StripeAccount,
      customer: newCustomer
    };

    this.http.post(bmxurl + "StripePIvoucher", this.PIRequest).subscribe(response => {
      this.PIReponse = (response as piresponsevoucher_returndata);

      if(this.PIReponse.succeeded)
      {
        const options = {
          layout: {
            type: 'accordion',
            defaultCollapsed: false,
          }
        };
  
        const appearance = {
          theme: 'stripe',
          labels: 'floating'
          
        };
  
        const elements = stripe.elements({clientSecret: this.PIReponse.data.clientSecret, appearance });
        const card = elements.create('payment', options);
        card.mount('#payment-element');
  
        card.addEventListener('change', (event: any) => {
          const displayError = document.getElementById('payment-errors');
          if (displayError) {
            if (event.error) {
              displayError.textContent = event.error.message;
            } else {
              displayError.textContent = '';
            }
          }
        });
  
        const paymentForm = document.getElementById('payment-form');
         if(paymentForm != null)
         {        paymentForm.addEventListener('submit', async (event) => {

          this.PauseTimerEffect = true;
          this.PaymentInProgress = true;
          event.preventDefault();
          const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {},
            redirect: "if_required"
          });
          const { paymentIntent } = await stripe.retrievePaymentIntent(this.PIReponse.data.clientSecret);
          if (paymentIntent.status == "succeeded")
          {
            setTimeout(() => {

              this.busyService.busy();

              //Go and get the booking and check the payment status
              let jvoucher: voucherreq  = {
                voucherid: returnedVoucher.voucherid,
                jwt: returnedVoucher.vouchertoken
              }
      
              this.http.post(bmxurl + "PublicVoucher", jvoucher).subscribe(response => {
                this.VoucherResponse = (response as voucher_returndata).data;
                
                if(this.VoucherResponse.payments != null)
                {
                  if(this.VoucherResponse.payments.length > 0)
                  {
                    this.VoucherConfirmationSummary = "Voucher Confirmation";
                    this.VoucherConfirmationText = "Thank you for purchasing a voucher, please see the details below:";
      
                    this.BookingComplete = true;
                    this.PaymentInProgress = false;

                    this.DoSummary(this.VoucherResponse);
                    this.hubConnection.stop();

                    this.busyService.idle();
                  }
                  else
                  {
                    this.VoucherConfirmationSummary = "Voucher Purchase In Progress";
                    this.VoucherConfirmationText = "Your payment is being processed, if the payment is successful you will receive a confirmation of the details of your voucher, if you have not received this in 24 hours, please contact the venue.";
            
                    this.Voucher.voucherpin = "****";
                    this.Voucher.vouchercode = "***-***";

                    this.BookingComplete = true;
                    this.PaymentInProgress = false;

                    this.DoSummary(this.VoucherResponse);
                    this.hubConnection.stop();

                    this.busyService.idle();
                  }
                }
                else
                {
                  this.VoucherConfirmationSummary = "Voucher Purchase In Progress";
                  this.VoucherConfirmationText = "Your payment is being processed, if the payment is successful you will receive a confirmation of the details of your voucher, if you have not received this in 24 hours, please contact the venue.";
          
                  this.BookingComplete = true;
                  this.PaymentInProgress = false;

                  this.DoSummary(this.VoucherResponse);
                  this.hubConnection.stop();

                  this.busyService.idle();
                }
              });
              }, 10000);
          }
          else
          {
            this.paymentError = "Your payment has failed, please try again.";
            paymentForm.style.removeProperty("display");

            this.PauseTimerEffect = false;
            this.PaymentInProgress = false;
          }
        });}

      }
      else
      {
        const paymentForm = document.getElementById('payment-form');
        if(paymentForm != null)
          {
            paymentForm.style.display = "none";
            this.PaymentInProgress = false;

            this.paymentError = "An error has occured, please contact the venue, a payment cannot be made at this time.";
          }
      }

    }, error => {
        const paymentForm = document.getElementById('payment-form');
        if(paymentForm != null)
        {
          paymentForm.style.display = "none";
          this.PaymentInProgress = false;
        }

        this.paymentError = "An error has occured, please contact the venue, a payment cannot be made at this time.";
    });
  }

}
