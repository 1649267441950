export const environment = {
  production: false,
  VenueName: "",
  apitarget: "https://dev-api.wannabook.online/",
  servicestarget: "https://dev-services.wannabook.online",
  //servicestarget: "https://8329-77-100-213-151.ngrok-free.app",
  PaymentAccountNumber: "pk_test_51NpZ7oJle3gYWSXYx6yfnZXLc3E8hyKxzsbFxkGzsYwT7aSPOCLhSx9kDiIcuQLGxsDTfzZOZflysCcv0O3iUAcK00kWAnhU50",
  version: "1.1.26",
  httpInterceptor: {
    allowedList: [`*`],
  },
};

// export const environment = {
//   production: true,
//   VenueName: "",
//   apitarget: "https://api2.wannabook.online/",
//   servicestarget: "https://services.wannabook.online",
//   PaymentAccountNumber: "pk_live_51NpZ7oJle3gYWSXYbKWDLn9aq8mhdhlKxuizcGWfhcKH3xVXm1fEKfL2Y0OmCHmyuQgOoxaBmhi4AAdPqjDqGYnM006QSbqZ4X",
//   //PaymentAccountNumber: "pk_test_51NpZ7oJle3gYWSXYx6yfnZXLc3E8hyKxzsbFxkGzsYwT7aSPOCLhSx9kDiIcuQLGxsDTfzZOZflysCcv0O3iUAcK00kWAnhU50",
//   version: "1.1.26",
//   httpInterceptor: {
//     allowedList: [`*`],
//   },
// };
