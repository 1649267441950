import { Routes } from '@angular/router';
import { TermsComponent } from './terms/terms.component';
import { HomeComponent } from './home/home.component';
import { BookingComponent } from './booking/booking.component';
import { BuyvoucherComponent } from './buyvoucher/buyvoucher.component';
import { PaymentComponent } from './payment/payment.component';
import { AppComponent } from './app.component';
import { EventsListComponent } from './events-list/events-list.component';
import { SubscribethanksComponent } from './subscribethanks/subscribethanks.component';

export const routes: Routes = [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'booking/:venue',
        component: BookingComponent,
      },
      {
        path: 'booking/:venue/:date',
        component: BookingComponent,
      },
      {
        path: 'booking/:venue/:title/:desc/:url/:image',
        component: BookingComponent,
      },
      // {
      //   path: 'events/:venue',
      //   component: EventsListComponent,
      // },
      {
        path: 'terms/:venue',
        component: TermsComponent,
      },
      {
        path: 'payment',
        component: PaymentComponent,
      },
      {
        path: 'subscriptions',
        component: SubscribethanksComponent,
      },
      {
        path: 'buyvoucher/:venue',
        component: BuyvoucherComponent
      }
];
