
<link rel="stylesheet" [href]="VenueCSS">

<div class="padding15px">
    <div class="publicImage"><img src={{publicImageURL}} width={{imageWidth}} height={{imageHeight}}></div>
    <div style="clear:both; width:100%"></div>

    <div style="text-align: centre;">
        
        <div *ngIf="Action=='success'"style="width: 100%;text-align: center; margin-bottom: 20px;">
            <p style="font-size:24px; font-weight:bold;">You have subscribed to {{Subscription}}.</p>
            <p style="font-size:20px;">Cost: <b>{{Price | currency:'GBP'}}</b> per month until cancelled</p>
        </div>

        <div *ngIf="Action=='manage'"style="width: 100%;text-align: center; margin-bottom: 20px; font-size:24px;">
            <p style="font-size:24px; font-weight:bold;">You have successfully managed your subscription.</p>
        </div>

        <div *ngIf="Action=='cancel'"style="width: 100%;text-align: center; margin-bottom: 20px;">
            <p style="font-size:24px; font-weight:bold;">Your action has been cancelled.</p>
        </div>
        
        <div style="width: 100%;text-align: center; margin-bottom: 20px;font-weight: bold;">
            <p style="font-style: italic; color:red">You can now close this window.</p>
        </div>
        

        <div style="background-color: white;text-align: center;padding-top: 25px;font-size: 18px;font-weight: bold;border-top: dashed 1px #ccc;color: #ccc;margin-top:20px">
            Version: {{version}}
            <br>
            <a href="https://wannabook.online">Powered by WannaBook</a>
            <div style="height:81px;">
              &nbsp;
          </div>
        </div>
    </div>
</div>
