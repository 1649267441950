import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule, RouterOutlet } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastModule} from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { Meta } from '@angular/platform-browser';
import LogRocket from 'logrocket';
import { environment } from '../../src/environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    NgxSpinnerModule,
    ToastModule,
    RouterModule
  ],
  providers: [ 
    {provide: DateAdapter, useClass: NativeDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS},
    MessageService,
 ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})

export class AppComponent implements OnInit {
  title = 'WannabookPublic';
  linkid = "";
  qTitle = "";

  constructor(private dateAdapter: DateAdapter<Date>, private meta: Meta, private route: ActivatedRoute,  private router: Router,) {
    this.dateAdapter.setLocale('en-GB');


    // this.route.queryParamMap.subscribe(params => {
    //  const lid = params.get('linkdata') || '';

    //  this.router.navigateByUrl("/booking/littlebrownmouse?LinkId=" + lid);
    //});


      //this.router.navigateByUrl("/booking/littlebrownmouse/linkid=" +  this.linkid);

    //if (environment.production) {
      //LogRocket.init('zslez2/wannabook');
    //}
  }




  ngOnInit(): void {


  }

    //   let splitlink: string[] = linkdata.split("!!!");
      
    //   if( splitlink[0] != null && splitlink[0] != "" )
    //     this.meta.updateTag({property: 'og:title', content: splitlink[0]});    

    //   if( splitlink[1] != null && splitlink[1] != "" )
    //     this.meta.updateTag({property: 'og:description', content:  splitlink[1]});

    //   if( splitlink[2] != null && splitlink[2] != "" )
    //     this.meta.updateTag({property: 'og:image', content:  splitlink[2]});    

    //   if( splitlink[3] != null && splitlink[3] != "" )
    //     this.meta.updateTag({property: 'og:url', content: splitlink[3]});
    // }


  //}

}
