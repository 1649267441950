import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MessageService } from 'primeng/api';
import { environment } from '../../environments/environment';
import { bmxurl } from '../../globals';
import { ActivatedRoute } from '@angular/router';
import { venuesettings_returndata } from '../../types/venuesettings_returndata';
import { Action } from 'rxjs/internal/scheduler/Action';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-subscribethanks',
  imports: [
      HttpClientModule,
      DatePipe,
      CurrencyPipe,
      CommonModule
    ],
    providers: [
      provideNativeDateAdapter(),
      HttpClientModule,
      MessageService
   ],
  standalone: true,
  templateUrl: './subscribethanks.component.html',
  styleUrl: './subscribethanks.component.css'
})
export class SubscribethanksComponent {

    version: string = environment.version;
    VenueCSS: any;
    VenueID: string = "";
    MinimalCustomerDetails: boolean = false;
    publicImageURL: string = "";
    imageHeight: number = 150;
    imageWidth: number = 150;
    defaultColour: string = "";
    EmailSMSConfirmation: string = "";
    GoogleTagManagerID: string = "";
    returnPage: string = "";
    SetupComplete: boolean = false
    httpResponse: any;
    Subscription: string = "";
    Price: string = "";
    Action: string = "";

      constructor(private http: HttpClient, private route: ActivatedRoute,private sanitizer: DomSanitizer) { 

        this.route.queryParamMap.subscribe(params => {
          this.VenueID = params.get('venueid') ?? '';
          this.Subscription = params.get('subscription') ?? '';
          this.Action = params.get('action') ?? '';
          this.Price = params.get('price') ?? '';

          if (this.Subscription != "")
          {
            this.Subscription = atob(this.Subscription);
          }

          if (this.Price != "")
          {
            this.Price = atob(this.Price);
          }

          let tempCSS = bmxurl + "/css/" + this.VenueID;
          this.VenueCSS = this.sanitizer.bypassSecurityTrustResourceUrl(tempCSS);
        });
      }

      ngOnInit(): void {

        this.http.get(bmxurl + "venuesettings/" + this.VenueID).subscribe(response => {
          this.httpResponse =  (response as venuesettings_returndata)
          
          if(this.httpResponse.succeeded)
          {
            this.httpResponse.data.venueSettings.forEach((element: { name: string; value: string | number; }) => {
              
              if(element.name != null)
              {
                localStorage.setItem(element.name, element.value.toString());
      
                if(element.name == "MinimalDetails")
                  this.MinimalCustomerDetails = element.value == "true" ? true : false;
                if(element.name == "PublicImgURL")
                  this.publicImageURL = element.value.toString();
                if(element.name == "PublicImageheight")
                  this.imageHeight = Number(element.value);
                if(element.name == "PublicImageWidth")
                  this.imageWidth = Number(element.value);
                if(element.name == "DefaultColour")
                  this.defaultColour = element.value.toString();
                if(element.name == "EMailSMSConfirmation")
                  this.EmailSMSConfirmation = element.value.toString();
                if(element.name == "GoogleTagManagerID")
                  this.GoogleTagManagerID = element.value.toString();
                if(element.name == "returnPage")
                  this.returnPage = element.value.toString();
              }
            });

          }
          else{

          }
          }, error => {

          });
      }
  }


